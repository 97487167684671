<template>
  <div>
    <md-card v-if="showForm">
      <md-card-header>
        <h4
          class="title"
          style="font-weight:bold"
        >
          Upload Form
        </h4>

        <ul class="subtitle">
          <li>
            Files size cannot be greater than 5MB. You can reduce the file size <a
              href="https://www.adobe.com/ie/acrobat/online/compress-pdf.html"
              target="_blank"
            >HERE</a>
          </li>
          <li>
            Files extension must be <b>.jpg/.png/.pdf</b>
          </li>
        </ul>
      </md-card-header>

      <md-card-content>
        <div
          v-if="hasApp"
          class="md-layout"
        >
          <div class="md-layout-item">
            <md-switch v-model="form.show_in_app">
              Show in APP
            </md-switch>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-text
              v-model="form.title"
              name="title"
              icon="description"
              label="Title"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <StudentProgrammesSelect
              v-model="form.programme_id"
              :student-id="$route.params.id"
              @change="onSelectProgramme"
            />
          </div>
          <div class="md-layout-item">
            <FileTypesSelect
              v-model="form.file_type_id"
            />
          </div>
          <div class="md-layout-item pt-3">
            <form-uploader
              v-model="form.file"
              :max-size="5*1024"
              @error="onFileError"
            >
              <md-button
                class="md-primary md-sm w-100"
              >
                Choose File
              </md-button>
              <b
                v-if="form.file"
                class="text-success"
              >{{ form.file.name }}</b>
            </form-uploader>
          </div>
        </div>
        <div
          v-if="form.file_type_id === 3 && package_id"
          class="md-layout"
        >
          <div class="md-layout-item">
            <PackagePaymentsSelect
              v-model="form.package_payment_id"
              :package-id="package_id"
            />
          </div>
          <div class="md-layout-item">
            <FormText
              v-model="form.marketing_remark"
              label="Remark for Sales Team"
              icon="description"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="md-primary md-sm w-100"
              @click="uploadForm"
            >
              <md-icon class="material-icons">
                save
              </md-icon>
              Upload
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-header>
        <md-button
          v-if="!showForm"
          class="md-primary md-sm"
          @click="showForm = true"
        >
          <md-icon class="material-icons">
            save
          </md-icon>
          Upload New File
        </md-button>
      </md-card-header>

      <md-card-content>
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getFiles"
          @onDelete="onDelete"
          @onDownload="onDownload"
          @onPreview="onPreview"
          @onSetShow="onSetShow"
          @onUnsetShow="onUnsetShow"
        />
      </md-card-content>
    </md-card>

    <div class="md-layout">
      <div class="md-layout-item text-center">
        <router-link
          :to="{name:'StudentResume'}"
          tag="md-button"
          class="md-primary"
        >
          Return
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import VueTable from '@/components/Tables/VueTable.vue';
import moment from 'moment';
import {
  FormText,
  FormUploader,
} from '@/components/Inputs';
import {
  FileTypesSelect,
  StudentProgrammesSelect,
  PackagePaymentsSelect,
} from '@/components/Inputs/selects';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormUploader,
    FormText,
    FileTypesSelect,
    StudentProgrammesSelect,
    PackagePaymentsSelect,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'programme_id',
            mask: 'Programme ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'created_at',
            mask: 'uploaded',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'file_type.name',
            mask: 'file type',
            sortable: true,
          },
          {
            title: 'programme',
            sortable: false,
            callback: (x) => {
              if (!x || x === '-') return '-';
              return `${moment(x.start_date).format(this.$store.getters['school_settings/settings'].date_format)} | ${x.course.title} "${x.status}"`;
            },
          },
          {
            title: 'payment.marketing_remark',
            mask: 'remark for sales agent',
          },
          {
            title: 'show_in_app',
            mask: 'shown in app',
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'created_at',
            mask: 'created at',
            sortable: true,
            dateFormat: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-success',
            tooltip: 'Show in App',
            callback: 'onSetShow',
            icon: 'cloud_queue',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Hide in App',
            callback: 'onUnsetShow',
            icon: 'cloud_off',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Preview File',
            callback: 'onPreview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Download File',
            callback: 'onDownload',
            icon: 'get_app',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete File',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showForm: false,
      fileTypes: [],
      programmes: [],
      form: {
        programme_id: null,
        file_type_id: null,
        package_payment_id: null,
        student_id: this.$route.params.id,
        title: null,
        file: null,
        marketing_remark: null,
        show_in_app: false,
      },
      package_id: null,
      hasApp: false,
    };
  },
  mounted() {
    this.request(`${this.$API.STUDENTS}${this.$route.params.id}`, 'get', { attributes: ['app_registration_date'] }, ({ data }) => {
      this.hasApp = !!data.app_registration_date;
      this.$refs.vtable.init();
    });
  },
  methods: {
    getFiles(params) {
      this.request(`${this.$API.STUDENTS + this.$route.params.id}/${this.$API.FILES.paginableUrl(params)}`, 'get', null, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onDelete = this.userIsFullAdmin || x.created_by === this.user.user_id;
          x.onSetShow = this.hasApp && x.show_in_app === false;
          x.onUnsetShow = this.hasApp && x.show_in_app === true;
          return x;
        });
      });
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting File',
        'Are you sure you want to delete this File?',
      ).then(() => {
        this.request(`${this.$API.STUDENTS + this.$route.params.id}/${this.$API.FILES}${item.file_id}`, 'delete', null, () => {
          this.fireSuccess('File deleted successfully!');
          this.$refs.vtable.init();
        });
      });
    },
    async onSetShow(item) {
      const data = await this.fireModal({
        title: 'Sharing Letter',
        html: 'You are sharing this letter in the app. Want to modify the push notification message?',
        input: 'text',
        inputPlaceholder: 'Push Notification Message',
      });

      this.request(`${this.$API.STUDENTS + this.$route.params.id}/${this.$API.FILES}${item.file_id}`, 'put', {
        show_in_app: true,
        message: data.value,
      }, () => {
        this.fireSuccess('File will be shown in APP from now on.');
        this.$refs.vtable.init();
      });
    },
    onUnsetShow(item) {
      this.request(`${this.$API.STUDENTS + this.$route.params.id}/${this.$API.FILES}${item.file_id}`, 'put', {
        show_in_app: false,
      }, () => {
        this.fireSuccess('File will be hidden in APP from now on.');
        this.$refs.vtable.init();
      });
    },
    onPreview(item) {
      this.openFileUrl(item.file_id);
    },
    onDownload(item) {
      if (item.path.substr(0, 4) === 'http') {
        const fileLink = document.createElement('a');
        const filename = item.path.split('/')[item.path.split('/').length - 1];
        fileLink.href = item.path;
        fileLink.setAttribute('download', filename);
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);

        fileLink.click();
      } else {
        this.request(
          `${this.$API.STUDENTS + this.$route.params.id}/${this.$API.FILES}${item.file_id}`,
          'download', null, ({ data }) => {
            const filename = item.path.split('/')[item.path.split('/').length - 1];
            downloadFile(data, filename);
          },
        );
      }
    },
    onFileError(error) {
      this.fireError(error);
    },
    async uploadForm() {
      let valid = true;
      if (this.form.file_type_id === 3 && !this.form.package_payment_id) {
        this.fireError('Select a payment to continue');
        valid = false;
      }

      if (valid) {
        let data = {};

        if (this.form.show_in_app) {
          data = await this.fireModal({
            title: 'Letter creation',
            html: 'You are sharing this letter in the app. Want to modify the push notification message?',
            input: 'text',
            inputPlaceholder: 'Push Notification Message',
          });
        }

        const formData = new FormData();
        formData.append('file', this.form.file);
        formData.append('title', this.form.title);
        formData.append('programme_id', this.form.programme_id);
        formData.append('student_id', this.form.student_id);
        formData.append('package_payment_id', this.form.package_payment_id);
        formData.append('file_type_id', this.form.file_type_id);
        formData.append('marketing_remark', this.form.marketing_remark);
        formData.append('show_in_app', this.form.show_in_app);
        if (data.value) {
          formData.append('message', data.value);
        }

        this.request(`${this.$API.STUDENTS + this.$route.params.id}/${this.$API.FILES}`, 'post', formData, () => {
          this.fireSuccess('File created successfully!');
          this.showForm = false;
          this.$refs.vtable.init();
          this.form = {
            programme_id: null,
            file_type_id: null,
            package_payment_id: null,
            student_id: this.$route.params.id,
            title: null,
            file: null,
            marketing_remark: null,
            show_in_app: false,
          };
        });
      }
    },
    onSelectProgramme(e) {
      this.package_id = e.package_id;
    },
  },
};
</script>

<style scoped>
.md-card-header .title {
  margin-bottom: 1em;
}
.md-card-header .subtitle li {
  margin: 0;
}
.md-card-header .subtitle li a {
  font-weight: bold;
  color: #9c27b0 !important;
}
</style>
